import React, { useContext, useState } from "react";
import './Users.scss';
import 'react-circular-progressbar/dist/styles.css';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import useForm from 'react-hook-form';
import AppContext from 'store/AppContext';
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useNavigate, useParams } from "react-router-dom";

const SaveUser = (props) => {
    const urlParams = useParams();
    const { languageObj = {}, appState } = useContext(AppContext)
    const { user_id = null } = urlParams;
    const { register, handleSubmit, errors, getValues, setValue } = useForm()
    const [isSaving, setIsSaving] = useState(false)
    const [userData, setUserData] = useState({})
    const [phoneNumber, setPhoneNumber] = useState("");
    const navigate = useNavigate();

    const handleOnChange = (value, data, event, formattedValue) => {
        setPhoneNumber(value.slice(data.dialCode.length));
    }
    const saveUserData = (data = {}) => {
        console.log(phoneNumber);
        if (phoneNumber.length < 9) {
            toast.warn("Please enter valid contact number.")
            return;
        }
        setIsSaving(true)
        delete data.password_match;
        let sendDataObj = {}
        let formData = new FormData();
        Object.keys(data).forEach(el => {
            sendDataObj[el] = data[el]
        })
        sendDataObj.phone = phoneNumber
        formData.append("insertdata", JSON.stringify({ 'data': sendDataObj }));
        Axios.post(configURL.create_user, formData).then(res => {
            setIsSaving(false)
            if (res.data.success === "true") {
                toast.success("User Saved.")
                navigate("/partner-user", { replace: true })
            } else {
                toast.warn(res.data.message)
            }
        })

    }



    const handleInputChange = ({ target }) => {
        let { name, value } = target
        setValue(name, value.trim(), { shouldValidate: true })
        setUserData({
            ...userData,
            [name]: value.trim()
        })
    }

    const SaveButton = props => {

        return (<button type="submit" className="ezi-btn btn-ripple user-info-action-save" disabled={isSaving}>
            {props.children} {isSaving && <Spinner animation="grow" color="white" size="sm" />}
        </button>)
    }


    return (
        <React.Fragment>
            <section className="Page-UsersEdit" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate("/partner-user")}>{languageObj.translate('Users.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{user_id ? 'Update User Details' : 'Add New User'}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <fieldset disabled={(appState.user && appState.user.id === user_id)}>
                    <form onSubmit={handleSubmit(saveUserData)} autoComplete="new-password">
                        <div className="user-edit-form-wrap">
                            <h3 className="user-edit-form-header">User login & Role</h3>

                            <div className="user-edit-form-body">
                                <div className="edit-form-inner-header">
                                    <span className="form-heading">Primary Information</span>
                                    <div className="user-info-action">
                                        <SaveButton>Save</SaveButton>
                                        <button type="button" className="user-info-action-delete" onClick={() => navigate(-1)}>
                                            Back
                                        </button>
                                    </div>
                                </div>
                                <div className="form-view-data">
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">First Name</span>
                                        <div className="form-error-value-wrap">
                                            <input type="text" className="form-view-input-value" name="name" ref={register({ required: true })} defaultValue={userData.name || ""} onChange={handleInputChange} />
                                            {errors.name && errors.name.type === 'required' && <span className={`error-message`}>Please enter Name.</span>}
                                        </div>
                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Email</span>
                                        <div className="form-error-value-wrap">
                                            <input
                                                type="text"
                                                className="form-view-input-value"
                                                name="email"
                                                autoComplete="new-password"
                                                ref={register({
                                                    required: true,
                                                    // eslint-disable-next-line
                                                    pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/i
                                                })} defaultValue={userData.email || ""}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && errors.email.type === 'required' && <span className={`error-message`}>Please enter primary email.</span>}
                                            {errors.email && errors.email.type === 'pattern' && <span className={`error-message`}>Please enter valid email.</span>}
                                        </div>

                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Mobile Number</span>
                                        <div className="form-error-value-wrap">
                                            {/* <input
                                                type="number"
                                                className="form-view-input-value"
                                                name="phone"
                                                ref={register({
                                                    required: true,
                                                    // eslint-disable-next-line
                                                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
                                                })}
                                                maxLength={15}
                                                minLength={10}
                                                defaultValue={userData.phone || ""}
                                                onChange={handleInputChange}
                                            /> */}
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                country={'in'}
                                                // value={phoneNumber}
                                                onChange={handleOnChange}

                                            />
                                        </div>

                                    </label>
                                    {!user_id && <><label className="form-view-field-wrap">
                                        <span className="form-view-label">Password</span>
                                        <div className="form-error-value-wrap">
                                            <input type="password" className="form-view-input-value" name="pwd" ref={register({ required: true, minLength: 8 })} onChange={handleInputChange} autoComplete="new-password" />
                                            {errors.password && errors.password.type === 'required' && <span className={`error-message`}>You must specify a password</span>}
                                            {errors.password && errors.password.type === 'minLength' && <span className={`error-message`}>Password should be atleast 8 character long</span>}
                                        </div>
                                    </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Confirm Password</span>
                                            <div className="form-error-value-wrap">
                                                <input type="text" className="form-view-input-value" name="password_match" ref={register({
                                                    required: true,
                                                    validate: value => (value === getValues()["pwd"])
                                                })} onChange={handleInputChange} />
                                                {errors.password_match && errors.password_match.type === 'required' && <span className={`error-message`}>You must specify a password</span>}
                                                {errors.password_match && errors.password_match.type === 'validate' && <span className={`error-message`}>The passwords do not match</span>}
                                            </div>
                                        </label></>}
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </section>
        </React.Fragment>
    )
}

export default SaveUser;