import React, { useState, useEffect } from 'react';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import { connect } from "react-redux";
import { SurveyCreatorComponent } from "survey-creator-react";
import "lib/survey-widget/ThumbsWidget";
import "lib/survey-widget/SmilyWidget";
import "lib/survey-widget/SmilyWidgetNew"
import "lib/survey-widget/CSATWidget";
import "lib/survey-widget/NPSWidget";
import "lib/survey-widget/PersonalInfoText";

const TemplateBuilderTab = ({ templateLocale, templateLocales, canSave, changeLocale, preview, saveTemplate, creator, handleCustomSave }) => {
    const [tabKey, setTabKey] = useState('template-builder');
    const [surveyCreator, setSurveyCreator] = useState();

    useEffect(() => {
        setSurveyCreator(creator);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [surveyCreator]);
    return (
        <React.Fragment>
            <div className="tablist_ezi survey-tab-container">
                <Tab.Container activeKey={tabKey} onSelect={k => setTabKey(k)}>
                    <div className="survey-tab-header-wrap">
                        <div className="tab-left-header">
                            <Nav variant="pills" >
                                <Nav.Item className="template-question-builder">
                                    <Nav.Link eventKey="template-builder">Question Builder</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item className="template-preview">
                                    <Nav.Link eventKey="template-preview" onClick={preview}>Preview</Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item className="survey-preview-btn-wrap">
                                    <Nav.Link>
                                        <div className='save-publish-btn-wrap'>
                                                <button type="button" onClick={handleCustomSave} className="test btn-ripple survey-preview-btn" disabled={!canSave}>Save</button>
                                                <button type="button" onClick={saveTemplate} className="test btn-ripple survey-preview-btn" disabled={!canSave}>Publish</button>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content className="survey-tab-content">
                        <Tab.Pane eventKey="template-builder">
                        {(surveyCreator && JSON.stringify(surveyCreator) === "{}") && <SurveyCreatorComponent creator={surveyCreator} />}
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="template-preview" >
                            <div className="template-preview-wrap">
                                <div className="languageDropdown">
                                    <label className="lang-text">Language :</label>
                                    <Dropdown alignRight>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <div className="flag-avtar-wrap">
                                                <label className="country-flag-label">{templateLocale || "English"}</label>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {templateLocales.map((item, index) => (
                                                <Dropdown.Item key={index}>
                                                    <div className="flag-img-wrap" onClick={() => changeLocale(item)}>
                                                        <label className="country-flag-label">{item.name}</label>
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div id="templatePreviewElement">
                                </div>
                            </div>
                        </Tab.Pane> */}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        templateLocale: state.template.templateLocale,
        templateLocales: state.template.templateLocales,
        canSave: state.template.canSave,
    }
}

export default connect(mapStateToProps)(TemplateBuilderTab);