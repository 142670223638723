import React, { useState } from "react";
import './EditCompany.scss';
import { Breadcrumb, Tab, Nav } from 'react-bootstrap';
import DefaultPartnerLogo from "../../assets/images/partner-default-logo.svg"; // default partner logo


const EditCompany = () => {

    const [key, setKey] = useState('general');

    return (
        <React.Fragment>
            <section className="Page-EditCompany" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Companies</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Add New</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">Edit Company</h1>
                        <div className="column-header-btn">
                            <button type="button" className="btn-ripple clear_all" >Clear all</button>
                            <button type="button" className="btn-ripple add-new">Save</button>
                        </div>
                    </div>
                </div>



                <div className="add-company-form-wrap">
                    <div className="company-info-header">
                        <div className="conpany-name-id-wrap">
                            <span className="title">Company name</span>
                            <span className="subtitle">Account ID</span>
                        </div>
                        <div className="company-add-logo-wrap">
                            <img alt="" src={DefaultPartnerLogo} className="company-default-logo" />
                            <div className="company-logo-upload-position">
                                <div className="company-logo-upload-wrapper">
                                    <span className="company-logo-upload-btn"></span>
                                    <input type="file" name="myfile" className="company-logo-upload-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablist_ezi">
                        <Tab.Container activeKey={key} onSelect={k => setKey(k)}>
                            <div className="tab-header-wrap">
                                <div className="tab-left-header">
                                    <Nav variant="pills" >
                                        <Nav.Item>
                                            <Nav.Link eventKey="general">General</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="additional">Additional</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="billing">Billing</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <Tab.Content>

                                <Tab.Pane eventKey="general">
                                    <label>Company name <input type="text" placeholder="Enter Company name" name="companyname" /> </label>
                                    <label>Company Website <input type="text" placeholder="Enter Company Website" name="companywebsite" /> </label>
                                    <label>Company Address <input type="text" placeholder="Enter Company Address" name="companyaddress " /> </label>
                                    <label><select name="industry"> <option>IT</option> <option> Software</option> </select> </label>
                                    <label>Industry Vertical<input type="text" placeholder="Enter Industry Vertical" name="industryvertical" /></label>
                                    <label>City<input type="text" placeholder="Enter City" name="city" /></label>
                                    <label>Zipcode<input type="text" placeholder="Enter Zipcode" name="zipcode" /></label>
                                </Tab.Pane>

                                <Tab.Pane eventKey="additional">
                                    <label>User name <input type="text" placeholder="Enter User name" name="username" /> </label>
                                    <label>Password<input type="password" placeholder="Enter Password" name="password" /> </label>
                                    <label>Login phone number<input type="text" placeholder="Enter Login phone number" name="loginphonenumber " /> </label>
                                    <label>Login email address<input type="text" placeholder="Enter Login email address" name="loginemailaddress" /> </label>
                                </Tab.Pane>

                                <Tab.Pane eventKey="billing">
                                    <label>Billing email address<input type="text" placeholder="Enter Billing email address" name="billingemailaddress" /> </label>
                                    <label>Subscription Plan<input type="text" placeholder="Enter Subscription Plan" name="subscriptionplan" /> </label>
                                    <label>Subscription Start Date<input type="text" placeholder="Enter Subscription Start Date" name="subscriptionstartdate" /> </label>
                                    <label>Subscription End Date<input type="text" placeholder="Enter Subscription End Date" name="subscriptionenddate" /> </label>
                                    <label>Payment mode<input type="text" placeholder="Enter Login email address" name="paymentmode" /> </label>
                                </Tab.Pane>

                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>

            </section>
        </React.Fragment>
    )
}

export default EditCompany;