import { useEffect, useState } from "react";

const useOutsideClick = (ref, callback) => {
  const [active, setActive] = useState(false);
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
      setActive(!active);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  },[active]);
};

export default useOutsideClick;