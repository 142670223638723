/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import './AddCompany.scss';
import { Breadcrumb } from 'react-bootstrap';
import useForm from 'react-hook-form';
import configURL from 'config/config';
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate, useLocation } from "react-router-dom";

const SaveCompany = () => {

    const { EziLoader } = useContext(AppContext)
    const { register, handleSubmit, errors, reset } = useForm();
    let urlParams = useParams();
    const location = useLocation();
    const benchMarkData = {
        benchmarkId: location.state.benchmarkId,
        BMname: location.state.BMname,
    }
    const [statements, setStatements] = useState([
        { statement: '', negative_score: '', positive_score: '', neutral_score: '' },
    ]);
    const [benchmarkUpdate, setBenchmarkUpdate] = useState(false);
    const [benchmarkEdit, setBenchmarkEdit] = useState(false);
    const navigate = useNavigate();


    /**
     * Adds a new statement to the statements array.
     *
     * @return {undefined} - This function does not return a value.
     */
    const addNewStatement = () => {
        let emptyFields = false;
        let checkSum = false;
        let checkTotal = false;

        //Statement repeat validation
        const newStatement = statements[statements.length - 1]?.statement.trim();
        if (newStatement) {
            const isStatementIncluded = statements.slice(0, -1).some(data => data.statement.trim().toLowerCase() === newStatement.toLowerCase());
            if (isStatementIncluded) {
                toast.warn("The statement should be unique.");
                return;
            }
        }


        statements.forEach(data => {
            if (data?.statement === "" || data?.negative_score === "" || data?.positive_score === "" || data?.neutral_score === "") {
                emptyFields = true;
                return;
            }
            let scoreSum = Number(data?.negative_score) + Number(data?.positive_score) + Number(data?.neutral_score);
            if (scoreSum < 1) {
                checkSum = true;
                return;
            }
            if (scoreSum > 100) {
                checkTotal = true;
                return;
            }
        });

        if (emptyFields) {
            toast.warn("Please fill above fields before adding new statement");
            return;
        }
        if (checkSum) {
            toast.warn("All fields values can not be 0");
            return;
        }
        if (checkTotal) {
            toast.warn("Sum of these three field value should not be greater than 100");
            return;
        }
        setStatements([...statements, { statement: '', negative_score: '', positive_score: '', neutral_score: '' }]);
    };

    /**
     * Updates the statements array with a new value at the specified index.
     *
     * @param {number} index - The index of the statement to update.
     * @param {Object} event - The event object containing the target property.
     */
    const handleStatementChange = (index, event) => {
        const { name, value } = event.target;
        const updatedStatements = [...statements];
        updatedStatements[index][name] = value;
        setStatements(updatedStatements);
    };

    /**
     * Deletes a statement from the statements array at the specified index.
     *
     * @param {number} index - The index of the statement to be deleted.
     * @return {undefined} 
     */
    const deleteStatement = (index) => {
        if (statements.length > 1) {
            const updatedStatements = [...statements];
            updatedStatements.splice(index, 1);
            setStatements(updatedStatements);
        }
        else {
            clearForm();
            toast.warn("All statements removed succesfully");
        }
    }

    /**
     * Submits form data to the server.
     *
     * @return {undefined} 
     */
    const submitFormData = () => {
        let emptyStatement = false;
        let checkSum = false;
        let checkTotal = false;

        //Statement repeat validation
        const newStatement = statements[statements.length - 1]?.statement.trim();
        if (newStatement) {
            const isStatementIncluded = statements.slice(0, -1).some(data => data.statement.trim().toLowerCase() === newStatement.toLowerCase());
            if (isStatementIncluded) {
                toast.warn("The statement should be unique.");
                return;
            }
        }
        statements.map((data, index) => {
            if (data?.statement === "" || data?.negative_score === "" || data?.positive_score === "" || data?.neutral_score === "") {
                emptyStatement = true;
                return;
            }
            let scoreSum = Number(data?.negative_score) + Number(data?.positive_score) + Number(data?.neutral_score);
            if (scoreSum < 1) {
                checkSum = true;
                return;
            }
            if (scoreSum > 100) {
                checkTotal = true;
                return;
            }

        })
        if (emptyStatement) {
            toast.warn("All fields are mandatory");
            return;
        }
        if (checkSum) {
            toast.warn("All fields values can not be 0");
            return;
        }
        if (checkTotal) {
            toast.warn("Sum of these three field value should not be greater than 100");
            return;
        }
        let formData = new FormData();
        formData.append('benchmark_id', benchMarkData.benchmarkId);
        formData.append('statements', JSON.stringify(statements));
        formData.append("account_id", urlParams?.account_id);
        EziLoader.show();
        Axios.post(configURL.adding_multiple_statements_into_benchmark, formData)
            .then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.message || "Benchmark statements Saved");
                    EziLoader.hide();
                }
                else {
                    toast.warn(response.data.message || "Something went wrong");
                    EziLoader.hide();
                }
            }).catch(err => {
                console.log(err.toString());
                EziLoader.hide();
            })
    }
    
    /**
     * Retrieves benchmark data from the server and updates the statements state.
     *
     * @return {void} This function does not return a value.
     */
    const getBenchmarkData = () => {
        let formData = new FormData();
        formData.append('benchmark_id', benchMarkData.benchmarkId);
        formData.append("account_id", urlParams?.account_id);
        EziLoader.show();
        Axios.post(configURL.get_statement_by_Benchmark_Id, formData)
            .then(response => {
                if (response.data.result.length > 0 && response !== undefined && response.status === 200) {
                    setStatements(response.data.result);
                    EziLoader.hide();
                } else {
                    setBenchmarkEdit(true);
                    toast.warn(response.data.message || "Something went wrong");
                    EziLoader.hide();
                }
            }).catch(err => {
                console.log(err.toString())
                EziLoader.hide();
            })
    }

    /**
     * Clears the form by resetting all the input fields and statements.
     *
     * @return {undefined} No return value.
     */
    const clearForm = () => {
        const emptyStatements = statements.map(() => ({
            statement: '',
            positive_score: '',
            neutral_score: '',
            negative_score: '',
        }));
        setStatements(emptyStatements);
        reset();
    };
    useEffect(getBenchmarkData, deleteStatement);
    return (
        <React.Fragment>
            <section className="Page-AddCompany">
                <form onSubmit={handleSubmit(submitFormData)} className="company-form">
                    <div className="breadcrumb_ezi bor-ner">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/dashboard")}>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/companies")}>Companies</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate(-1)}>Benchmark</Breadcrumb.Item>
                            <Breadcrumb.Item >Edit Benchmarks</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="breadcrumb_ezi">
                        <div className="column-header benchmark-list-header">
                            <h1 className="page-heading">{benchMarkData.BMname}</h1>
                            <div className="column-header-btn">
                                { benchmarkUpdate && <> <button type="button" className="btn-ripple clear_all" onClick={clearForm} >Clear all</button>
                                <button type="submit" className="btn-ripple add-new" title={'Save'} >Save</button></>}
                                {!benchmarkUpdate && <button type="btn" className="btn-ripple add-new" onClick={() => setBenchmarkUpdate(!benchmarkUpdate)} title={'Update'} >{benchmarkEdit ? 'Edit' : 'Update'}</button>}
                            </div>
                        </div>
                    </div>
                    <div className="add-company-form-wrap margin-wrap">
                        <div className="list-header">
                            <div className="statement-head">statements</div>
                            <div className="Pos-head">Positive</div>
                            <div className="neu-head">Neutral</div>
                            <div className="neg-head">Negative</div>
                            {benchmarkUpdate &&<div className="action-head">Actions</div>}
                        </div>
                        <div className="company-info-header">
                            <div className="conpany-name-id-wrap">
                                <span className="title"></span>
                            </div>
                        </div>
                        {statements.map((item, index) => (
                            <div className="tablist_ezi" key={index}>
                                <div className="sub-benchmark-div-wrraper">
                                    <div>
                                        <label className="statement-input">
                                            <input
                                                type="text"
                                                placeholder="Write the statements for your benchmark."
                                                name={`statement`}
                                                maxLength={150}
                                                value={item?.statement}
                                                ref={register({ required: true })}
                                                onChange={(event) => handleStatementChange(index, event)}
                                                disabled={!benchmarkUpdate}
                                            />
                                            {errors.positive_score && errors.positive_score.type === 'required' &&
                                                <span className="error_cu">statement is required</span>}
                                        </label>
                                    </div>
                                    <div className="input-div">
                                        <div className="scores-input pos-score">
                                            <label>
                                                <input
                                                    type="number"
                                                    placeholder="Positive"
                                                    min="0" max="100"
                                                    name="positive_score"
                                                    value={item?.positive_score}
                                                    ref={register({
                                                        required: 'Score is required',
                                                        min: { value: 0, message: 'Score must be at least 0' },
                                                        max: { value: 100, message: 'Score must be at most 100' }
                                                    })}
                                                    onChange={(event) => handleStatementChange(index, event)}
                                                    disabled={!benchmarkUpdate}
                                                />
                                                {errors.positive_score && errors.positive_score.type === 'required' &&
                                                    <span className="error_cu">score is required</span>}
                                            </label>
                                        </div>
                                        <div className="scores-input">
                                            <label>
                                                <input
                                                    type="number"
                                                    min="0" max="100"
                                                    placeholder="Neutral"
                                                    name="neutral_score"
                                                    value={item?.neutral_score}
                                                    ref={register({
                                                        required: 'Score is required',
                                                        min: { value: 0, message: 'Score must be at least 0' },
                                                        max: { value: 100, message: 'Score must be at most 100' }
                                                    })}
                                                    onChange={(event) => handleStatementChange(index, event)}
                                                    disabled={!benchmarkUpdate}
                                                />
                                                {errors.positive_score && errors.positive_score.type === 'required' &&
                                                    <span className="error_cu">score is required</span>}
                                            </label>
                                        </div>
                                        <div className="scores-input">
                                            <label>
                                                <input
                                                    type="number"
                                                    placeholder="Negative"
                                                    min="0"
                                                    max="100"
                                                    name="negative_score"
                                                    value={item?.negative_score}
                                                    ref={register({
                                                        required: 'Score is required',
                                                        min: { value: 0, message: 'Score must be at least 0' },
                                                        max: { value: 100, message: 'Score must be at most 100' }
                                                    })}
                                                    onChange={(event) => handleStatementChange(index, event)}
                                                    disabled={!benchmarkUpdate}
                                                />
                                                {errors.negative_score && (
                                                    <span className="error_cu">{errors.negative_score.message}</span>
                                                )}
                                            </label>
                                        </div>
                                        {benchmarkUpdate && <><div>
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip>delete statement</Tooltip>}>
                                                <button type="button" className="removBenchmarkButton" disabled={item?.mapped} onClick={() => { deleteStatement(index) }}>Delete</button></OverlayTrigger>
                                        </div>
                                            {index === statements.length - 1 && <div>
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip>Add new statement</Tooltip>}>
                                                    <button type="button" className="addBenchmarkButton" onClick={() => addNewStatement()}>Add</button></OverlayTrigger>
                                            </div>}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}
export default SaveCompany;















