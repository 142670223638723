import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element }) => {
    const location = useLocation();
    const appState = useSelector(state => state.app.appState);
    const isLoggedIn = () => {
        // const { appState = {} } = this.props
        if (appState?.isLoggedIn !== undefined && appState?.isLoggedIn === true && appState?.user && appState?.user?.auth_token && appState?.user?.auth_token !== "") {
            return true
        } else {
            return false
        }
    }
    if (isLoggedIn()) {
        return element; // Render the protected component
    } else {
        localStorage.setItem('previousPath', location.pathname); // Save the current path
        return <Navigate to="/" replace />; // Redirect to login if not logged in
    }
};

export default PrivateRoute;
