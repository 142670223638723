import React, { useState, useEffect, useRef, useContext } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import { Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { getFirstWord } from 'utility/helper'
import './CategoryTemplateDashboard.scss';
import EziLoader from 'components/EziLoader';
import { toast } from "react-toastify";
import CategoryFilter from "components/SearchFilter";
import SweetSearch from "components/SweetSearch";
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import AppContext from 'store/AppContext';
import { useNavigate, useLocation } from "react-router-dom";


const CategoryTemplateDashboard = (props) => {
    const location = useLocation();
    const { languageObj = {} } = useContext(AppContext)
    const [defaultCategory, setDefaultCategory] = useState({ ...location.state, description: "" });
    const [categoryMetaData, setCategoryMetaData] = useState({})
    const [categories, setCategories] = useState([]);
    const [categoryTemplates, setCategoryTemplates] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filterType, setFilterType] = useState(location.state.template_status || "all");
    const perPage = 10;
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const inputSearch = useRef(null);
    var searchTimer = null;
    const navigate = useNavigate();

    /**
     * Get Selected/Default category MetaData.
     */
    const getCategoryMetaData = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        let response = await Axios.post(configURL.partner_category_templates_metadata_url, formData);
        if (response.data.success !== undefined && response.data.success === true) {
            setCategoryMetaData(response.data.results, getCategoryTemplates());
        } else {
            setLoading(false);
            toast.warn(response.data.message);
        }
    }

    /**
     * Get Searched category.
     * 
     * @param {string} search Search string.
     */
    const getCategoriesListing = (search = "") => {
        let formData = new FormData();
        formData.append("search", search);
        formData.append("type", "template");
        Axios.post(configURL.partner_template_category, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                let result = response.data.results;
                const categoryData = [];
                result.forEach(item => {
                    categoryData.push({ id: item.id, name: item.category_name, description: item.description });
                });
                setCategories(categoryData);
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    /**
     * Get Category templates.
     * 
     * @param {object} obj category object
     */
    const changeTemplatesCategory = (obj = {}) => {
        setDefaultCategory({
            category_id: obj.id || null,
            category_name: obj.name || null,
            description: obj.description || ""
        })
        navigate("/template-dashboard/category-templates", {
            state: {category_id: obj.id,
            category_name: obj.name}, 
        }, {replace: true})
    }

    /**
     * Get Perticular template types.
     * 
     * @param {string} type template types
     * @param {number} page Page number
     */
    const getCategoryTemplates = (page = 1, type = filterType) => {
        let searchVal = inputSearch.current.value;
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        formData.append("per_page", perPage);
        formData.append("page", page);
        formData.append("type", type);
        formData.append("search", searchVal);
        Axios.post(configURL.partner_category_templates_url, formData).then(response => {
            console.log(response);
            setLoading(false);
            setSearchLoading(false)
            if (response.data.success !== undefined && response.data.success === true) {
                setCategoryTemplates(response.data.results);
                setPagination(response.data.pagination);
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    /**
     * Filter Data based on search.
     * 
     * @param {string} type Input value
     */
    const handleFilterSearch = ({ target }) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getCategoryTemplates(1, filterType);
        }, 800);
    }

    /**
     * Filter Data based on Click.
     * 
     * @param {string} type Filter Type
     */
    const handleFilterChange = (type = "all") => {
        setLoading(true);
        setFilterType(type);
        getCategoryTemplates(1, type);
    }

    /**
     * Handle Template Delete
     * 
     * @param {number} id Survey Id
     */
    const handleTemplateDelete = (id) => {
        confirmAlert({
            title: 'Delete Template',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let filteredData = categoryTemplates.filter(item => item.id !== id);
                        setCategoryTemplates(filteredData);
                        let formData = new FormData();
                        formData.append("id", id);
                        Axios.post(configURL.partner_delete_template, formData).then(response => {
                            if (response.data.success !== undefined && response.data.success === true) {
                                toast.success(response.data.message);
                            } else {
                                toast.warn(response.data.message);
                            }
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    /**
     * Handle Pagination
     * 
     * @param {string} type Filter Type
     */
    const handlePagination = (page = 1) => {
        setLoading(true);
        getCategoryTemplates(page, filterType);
    }

    const handleCreateTemplate = () => {
        if (defaultCategory.category_id && defaultCategory.category_id !== "") {
            navigate(`/template-dashboard/add-new/${defaultCategory.category_id}`, {
                state:{action: "create-template",
                category_name: defaultCategory.category_name}
            })
        } else {
            navigate('/template-dashboard/categories', {
                state:{action: "create-new"}
            });
        }
    }


    useEffect(() => {
        getCategoryMetaData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCategory]);
    useEffect(getCategoriesListing, []);

    return (
        <React.Fragment>
            <section className="Page-CategoryDashboard">

                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate("/template-dashboard")}>
                            {languageObj.translate('Template.1')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate(-1)}>{defaultCategory.category_name}</Breadcrumb.Item>
                        <Breadcrumb.Item>Templates</Breadcrumb.Item>
                    </Breadcrumb>
                    <CategoryFilter handleSelect={changeTemplatesCategory} data={categories} defaultSelected={defaultCategory.category_name} />
                    <span className="template-category-subtitle"> {(defaultCategory.description !== undefined) ? defaultCategory.description : ''} </span>
                </div>

                <div className="category-dashboard-card-wrap">
                    <div className={`category-dashboard-card total_template ${(filterType === "all") && "active"}`} onClick={() => handleFilterChange("all")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.total_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">{languageObj.translate('TotalTemplates.1')}</span>
                        </div>
                        <div className="category_progress_ring_wrap">
                            <div className="category_progress_ring">
                                <CircularProgressbar className="category_progress_circle" value={categoryMetaData.published_template_percentage || 0} text={`${parseInt(categoryMetaData.published_template_percentage) || 0}%`}
                                />
                            </div>
                            <span className="publish-templates-text">{languageObj.translate('PublishedTemplates.1')}</span>
                            <span className="unpublish-templates-text">Unpublished Templates</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card active_template ${(filterType === "active") && "active"}`} onClick={() => handleFilterChange("active")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.active_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">Active Templates</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card publish_template ${(filterType === "published") && "active"}`} onClick={() => handleFilterChange("published")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.published_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">Published</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card unpublish_template ${(filterType === "unpublished") && "active"}`} onClick={() => handleFilterChange("unpublished")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.unpublished_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">Unpublished</span>
                        </div>
                    </div>

                    <div className={`category-dashboard-card create-survey-template `} onClick={handleCreateTemplate}>
                        <span className="create-survey-template-ic"></span>
                        <p className="create-survey-template-name">Create {(defaultCategory.category_id && defaultCategory.category_id !== "") ? defaultCategory.category_name : ""} Template</p>
                    </div>
                </div>

                <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />

                <div className="category-dashboard-table">

                    <div className="category-dashboard-table-row category-table-heading">
                        <div className="category-dashboard-table-cell"> Template Name </div>
                        <div className="category-dashboard-table-cell"> Owner </div>
                        <div className="category-dashboard-table-cell"> Status </div>
                        <div className="category-dashboard-table-cell"> Is Active </div>
                        {/* <div className="category-dashboard-table-cell"> Responses </div> */}
                        <div className="category-dashboard-table-cell"> Action </div>
                    </div>
                    {
                        categoryTemplates.length > 0 ? categoryTemplates.map((item, index) => {
                            return (
                                <div key={index} className="category-dashboard-table-row">
                                    <div className="category-dashboard-table-cell" data-title="Template Name">
                                        <div className={`category-table-template-wrap `} onClick={() => {
                                            navigate(`/template-dashboard/edit-template/${item.category_id}/${item.id}`, {
                                              state:{  action: "edit-template",
                                                category_name: defaultCategory.category_name}
                                            })
                                        }}>
                                            {/* <img alt="" src={imageUrl} className="category-template-logo" /> */}
                                            <div className="category-table-template-text-wrap">
                                                <span className="category-table-template-name">{item.name}</span>
                                                <span className="category-table-create">Last Modified: {getFirstWord(item.updated_at)}   |   Created on: {getFirstWord(item.created_at)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="category-dashboard-table-cell" data-title="Owner"> {item.owner_name || "Dummy"} </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Status"> {(item.ispublished) ? "Published" : "Unpublished"} </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Is Active"><span className={`isactive ${item.status}`}>{item.status}</span></div>
                                    <div className="category-dashboard-table-cell" data-title="Action">
                                        <button type="button" className={`delete_data`} onClick={() => {
                                            handleTemplateDelete(item.id)
                                        }}></button>
                                        <OverlayTrigger overlay={<Tooltip>Copy Template</Tooltip>}>
                                            <button type="button" className={`clone_template `} onClick={() => {
                                                navigate(`/template-dashboard/edit-template/${item.category_id}/${item.id}`, {
                                                    state:{action: "clone-template",
                                                    category_name: defaultCategory.category_name}
                                                })
                                            }}>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            )
                        }) : <h4 style={{ textAlign: "center" }}>No result Found</h4>
                    }
                </div>
                <div className="pagination-plugin-wrap category-pagination-formatter">
                    <Pagination
                        activePage={pagination.current_page}
                        itemsCountPerPage="10"
                        totalItemsCount={pagination.total}
                        onChange={handlePagination}
                        hideDisabled={true}
                    />
                </div>
            </section>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default CategoryTemplateDashboard;