import React , { useState, useEffect, useContext } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import * as SurveyCore from "survey-core";
import "survey-core/survey.i18n";
import "jquery-ui/themes/base/all.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "./TemplateBuilder.scss";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import "pretty-checkbox/dist/pretty-checkbox.css";
import * as widgets from "surveyjs-widgets";
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import configURL from 'config/config';
import EziAlert from 'components/Alert';
import TemplateBuilderTab from './TemplateBuilderTab';
import * as CONFIG from "./Constants";
import EziLoader from "components/EziLoader";
import ThemeMappingModal from "../components/ThemeMappingModal";
import { QUESTION_PROPERTY_VISIBILITY, CUSTOM_LANGUAGES } from "constants/SurveyConstants";
import { connect } from "react-redux";
import * as AppActions from "store/actions";
import QuestionMapping from './QuestionMapping';
import { uniqueGenerator, surveyQuestionModifier, canSaveBuilderQuestions, mapParentChild, doMarkdown, CkEditor_ModalEditor } from "utility/helper";
import * as SurveyCreatorCore from "survey-creator-core";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "nouislider/distribute/nouislider.css";
import { settings } from "survey-creator-core";

/**Custom  widgets**/
// widgets.prettycheckbox(SurveyCore);
// widgets.select2(SurveyCore, $);
// widgets.inputmask(SurveyCore);
// widgets.ckeditor(SurveyCore);
// widgets.autocomplete(SurveyCore, $);
/**Custom  widgets end**/

/**Custom  widgets**/
widgets.prettycheckbox(SurveyCore);
widgets.select2(SurveyCore, $);
widgets.inputmask(SurveyCore);
widgets.jquerybarrating(SurveyCore, $);
widgets.jqueryuidatepicker(SurveyCore, $);
widgets.select2tagbox(SurveyCore, $);
widgets.sortablejs(SurveyCore);
// widgets.ckeditor(SurveyCore);
widgets.autocomplete(SurveyCore, $);
widgets.bootstrapslider(SurveyCore);
widgets.nouislider(SurveyCore);
/**Custom  widgets end**/

SurveyCore.slk(
	CONFIG.SURVEYJS_LICENCE_KEY,
);

var templatePreviewModel;
var templateCreator = {};
var isTranTabInitialised = false
var template_locales = [];
var inputSearchTimer = null;
var templateJSON = {};
var defaultLocale = "en";
var theme_json = "";

const TemplateBuilder  = (props) => {
	const { category_id, template_id } = useParams();
	const [canSaveQuestion, setCanSaveQuestion] = useState(false);
	const [loading, setLoading] = useState(true);
	const [confirmSave, setConfirmSave] = useState(false);
	const [showQuestionMap, setShowQuestionMap] = useState(false);
	const [showQuestionBank, setShowQuestionBank] = useState(false);
	const [showThemeModal, setShowThemeModal] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();


	const updateInitialQuestion = (question_json, theme_json, locales) => {
		setLoading(false);
		if (question_json) {
			let canSave = canSaveBuilderQuestions(question_json)
			props.dispatchCanSave(canSave)
			templateCreator.text = JSON.stringify(question_json);
			if(theme_json) templateCreator.theme = theme_json;
			template_locales = locales;
			templateJSON = question_json;
		}
	}

	const getInitialData = async (cb) => {
		setLoading(true)
		let formData = new FormData();
		formData.append("category_id", category_id);
		formData.append("template_id", template_id);
		let templateData = await Axios.post(configURL.partner_get_template_builder_initals, formData)
		if (templateData.data.success !== undefined && templateData.data.success === true) {
			// let locales = [...new Set(templateData.data.results.template_locales.filter(el => el !== "")), 'en']
			let locales = templateData.data.results.template_locales;
			template_locales = locales;
			 let theme_json = (templateData.data.results.theme_json && templateData.data.results.theme_json !== null) ? JSON.parse(templateData.data.results.theme_json) : 	templateData.data.results.theme_json;
			
			props.dispatchInitial({
				templateName: templateData.data.results.template_name || "",
				categoryName: templateData.data.results.category_name || "",
				templateStatus: templateData.data.results.status || "",
				publishStatus: templateData.data.results.ispublished || "",
			})
			updateInitialQuestion(templateData.data.results.template_json, theme_json, locales)
		}
		setLoading(false)
		cb()
	}

	const renderTemplateCreator = () => {
		props.dispatchResetTemplateData()
		settings.translation.maximumSelectedLocales = 40;
		for (let key in CONFIG.TOOLBAR_NAMES) {
			SurveyCreatorCore.editorLocalization.getLocale(SurveyCreatorCore.editorLocalization.currentLocale).ed[key] = CONFIG.TOOLBAR_NAMES[key];
		}
		/**Change Template Text */
		SurveyCreatorCore.editorLocalization.getLocale(SurveyCreatorCore.editorLocalization.currentLocale).ed.designer = "Template Designer";
		SurveyCreatorCore.editorLocalization.getLocale(SurveyCreatorCore.editorLocalization.currentLocale).ed.logic = "Template Logic";
		SurveyCreatorCore.editorLocalization.getLocale(SurveyCreatorCore.editorLocalization.currentLocale).ed.testSurvey = "Preview Template";
		/** Change Template Text End */

		/** CK Editor Property Add Start */
		// if (!REMOVE_THIRD_PARTY_API.includes(appStore?.current_app_detail?.id)) {
		// 	// SurveyCreatorCore.SurveyPropertyModalEditor.registerCustomWidget("html", CkEditor_ModalEditor);
		// }
		/** CK Editor Property Add End */

		templateCreator = new SurveyCreator(CONFIG.BUILDER_OPTIONS);
		let toolBoxItems = templateCreator.toolbox.items;
		templateCreator.toolbox.orderedQuestions = CONFIG.TOOLBOX_ORDERS;
		// templateCreator.isAutoSave = false;

		SurveyCore.surveyLocalization.currentLocale = templateJSON.locale || 'en';
		SurveyCore.surveyLocalization.defaultLocale = templateJSON.locale || 'en';
		SurveyCore.surveyLocalization.canMergeLocaleWithDefault = true;

		if(theme_json) {
			templateCreator.theme = theme_json || "";
		}
		
		for (let key in templateCreator.toolbox.items) {

			if (toolBoxItems[key]['id'] === "hiv2") {
				toolBoxItems[key]['json']['choices'] = [
					{ value: '1', text: "Bored" },
					{ value: '2', text: "Upset" },
					{ value: '3', text: "At Ease" },
					{ value: '4', text: "Joyful" },
					{ value: '5', text: "Loving it" }
				];
			}
			if (toolBoxItems.hasOwnProperty(key)) {
				if (CONFIG.TOOLBOX_NAME.hasOwnProperty(toolBoxItems[key]['name'])) {
					templateCreator.toolbox.replaceItem(toolBoxItems[key]);
					toolBoxItems[key]['title'] = CONFIG.TOOLBOX_NAME[toolBoxItems[key]['name']];
					toolBoxItems[key]['tooltip'] = CONFIG.TOOLBOX_NAME[toolBoxItems[key]['name']];
				}
			}
		}
		templateCreator.onActiveTabChanged.add((sender, options) => {
			if (options.tabName === "translation") {
				isTranTabInitialised = true;
				options.model.setSelectedLocales(template_locales);
			}
			else
			{
				isTranTabInitialised = false;
			}
		});
		SurveyCore.JsonObject.metaData.addProperty("question", "id");
		SurveyCore.JsonObject.metaData.addProperty("question", "question_type");
		SurveyCore.JsonObject.metaData.addProperty("question", "parent_question_id");
		// templateCreator.onElementDoubleClick.add(function (sender, options) {
		// 	sender.showQuestionEditor(options.element);
		// });
		templateCreator.onQuestionAdded.add(function (sender, options) {
			options.question.id = uniqueGenerator()
			options.question.question_type = CONFIG.QUESTION_TYPES[options.question.getType()] || options.question.getType()
		});
		SurveyCore.Serializer.addProperty("matrix", {
			name: "Types",
			title: "Types",
			choices: Object.keys(CONFIG.LIKERT_CHOICES),
		});
		// templateCreator.saveSurveyFunc = saveTemplateQuestions;

		templateCreator.saveSurveyFunc = (saveNo, callback) => { 
			// debugger
			// If you use a web service:
			saveTemplateQuestions(saveNo,callback);			
		  };
		SurveyCore.Serializer.findProperty("question", "question_type").visible = false;
		SurveyCore.Serializer.findProperty("question", "id").visible = false;
		SurveyCore.Serializer.findProperty("question", "parent_question_id").visible = false;
		SurveyCore.Serializer.findProperty("question", "name").visible = false;
		SurveyCore.Serializer.findProperty("page", "name").visible = false;
		SurveyCore.Serializer.addProperty("matrix", {
			name: "Options",
			title: "Options",
			dependsOn: "Types",
			choices: function (obj) {
				var entity = !!obj ? obj.Types : null;
				if (entity != null) {
					return Object.keys(CONFIG.LIKERT_CHOICES[entity]);
				}
				else {
					return [];
				}
			}
		});
		/** -----Custom Languages--- */
		Object.keys(CUSTOM_LANGUAGES).forEach(key => {
			SurveyCore.surveyLocalization.locales[key] = CUSTOM_LANGUAGES[key].localeStrings;
			SurveyCore.surveyLocalization.localeNames[key] = CUSTOM_LANGUAGES[key].label;
		})
		SurveyCore.JsonObject.metaData.addProperty("personalinfo", { name: "isUnique:switch", default: false });
		SurveyCore.JsonObject.metaData.addProperty("question", { name: "hasParentQuestion:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("dropdown", { name: "isIdentifier:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("radiogroup", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCore.JsonObject.metaData.addProperty("checkbox", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCore.JsonObject.metaData.addProperty("nps_rating", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCore.JsonObject.metaData.addProperty("rating_csat", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCore.JsonObject.metaData.addProperty("smily", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCore.JsonObject.metaData.addProperty("camera", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCore.JsonObject.metaData.addProperty("hiv2", { name: "isIdentifier:switch", default: false, category: "general" })
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("isIdentifier");
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("isUnique");
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("hasParentQuestion");
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("Types");
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("Options");
		/** Adding is overriding start*/
		SurveyCore.JsonObject.metaData.addProperty("rating_csat", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("nps_rating", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("smily", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("hiv2", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("camera", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("radiogroup", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("rating", { name: "isOverriding:switch", default: false, category: "general" });
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("isOverriding");
		/** Adding is overriding end*/

		/** Adding has calculation start*/
		SurveyCore.JsonObject.metaData.addProperty("radiogroup", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("rating", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("barrating", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("nps_rating", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("rating_csat", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("smily", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("camera", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCore.JsonObject.metaData.addProperty("hiv2", { name: "hasCalculation:switch", default: false, category: "general" });
		SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.question.properties.push("hasCalculation");
		/** Adding has calculation end*/

		templateCreator.onPropertyValueChanging.add(function (sender, options) {
			if (options.propertyName === "Options") {
				options.obj.columns = CONFIG.LIKERT_CHOICES[options.obj.Types][options.newValue];
			}
		});
		templateCreator.onPageAdded.add((sender, options) => {
			let { page: { questions = [] } } = options
			questions.forEach(el => {
				el.id = uniqueGenerator()
			})
		});

		templateCreator.onElementAllowOperations.add(function (sender, options) {
			options.allowAddToToolbox = false;
		});

		const themeMapping = new SurveyCore.Action({
			id: "thememapping",
			visible: new SurveyCore.ComputedUpdater(() => templateCreator.activeTab === "designer"),
			title: "Theme Mapping",
			iconName: "icon-thememap",
			action: () => {
				if (location.state && location.state.canModify === false) {
					toast.warn("Template is non-editable")
					return;
				}
				setShowThemeModal(true);
			}
		})
		
		// templateCreator.onCanShowProperty.add(function (_, options) {
		// 	if (options.obj.getType() === "rating_csat") {
		// 		options.canShow = QUESTION_PROPERTY_VISIBILITY["rating_csat"].includes(options.property.name)
		// 	}
		// 	if (options.obj.getType() === "nps_rating") {
		// 		options.canShow = QUESTION_PROPERTY_VISIBILITY["nps_rating"].includes(options.property.name)
		// 	}
		// 	if (options.obj.getType() === "smily") {
		// 		options.canShow = QUESTION_PROPERTY_VISIBILITY["smily"].includes(options.property.name)
		// 	}
		// 	if (options.obj.getType() === "yesno") {
		// 		options.canShow = QUESTION_PROPERTY_VISIBILITY["yesno"].includes(options.property.name)
		// 	}
		// 	if (options.obj.getType() === "hiv2") {
		// 		options.canShow = QUESTION_PROPERTY_VISIBILITY["hiv2"].includes(options.property.name)
		// 	}
		// 	if (options.obj.getType() === "camera") {
		// 		options.canShow = QUESTION_PROPERTY_VISIBILITY["camera"].includes(options.property.name)
		// 	}
		// });

		const questionMap = new SurveyCore.Action({
			id: "questionmapping",
			visible: new SurveyCore.ComputedUpdater(() => templateCreator.activeTab === "designer"),
			title: "Questions Mapping", 	
			iconName : "icon-questionmap",
			action: () => {
				if (location.state && location.state.canModify === false) {
					toast.warn("Template is non-editable")
					return;
				}
				setShowQuestionMap(true);
			}
		})

		// const questionBank = new SurveyCore.Action({
		// 	id: "questionbank",
		// 	visible: new SurveyCore.ComputedUpdater(() => templateCreator.activeTab === "designer"),
		// 	title: "Questions Bank",
		// 	iconName : "icon-questionbank",
		// 	action: () => {
		// 		if (location.state && location.state.canModify === false) {
		// 			toast.warn("Template is non-editable")
		// 			return;
		// 		}
		// 		setShowQuestionBank(true);
		// 	}
		// });
		templateCreator.toolbar.actions.push(themeMapping);
		templateCreator.toolbar.actions.push(questionMap);
		// templateCreator.toolbar.actions.push(questionBank);

		/** CK Editor Property Configuration Start */
		templateCreator.survey.onTextMarkdown.add(doMarkdown);
		templateCreator.onDesignerSurveyCreated.add(function (editor, options) {
			options.survey.onTextMarkdown.add(doMarkdown);
		});
		templateCreator.onTestSurveyCreated.add(function (editor, options) {
			options.survey.onTextMarkdown.add(doMarkdown);
		});
		/** CK Editor Property Configuration End */
		templateCreator.hideAdvancedSettings = true;
		// templateCreator.toolbarItems().reverse();
		// templateCreator.toolbox.items.forEach((item, i) => {
		// 	let toolNode = item;
		// 	let nodeIconName = toolNode.iconName;
		// 	toolNode.iconName = `${nodeIconName}_custom`;
		// 	templateCreator.toolbox.replaceItem(toolNode);
		// });
		// SurveyCreator.StylesManager.ThemeColors["default"] = CONFIG.BUILDER_THEME
		// SurveyCreator.StylesManager.applyTheme("default");
		templateCreator.text = null;
		if (location.state && location.state.canModify === false) {
			templateCreator.readOnly = true
		}
		getInitialData(() => {
			setCanSaveQuestion(true);
		})
	}
	useEffect(() => {
		templatePreviewModel = {};
		templateCreator = {};
		isTranTabInitialised = false
		template_locales = [];
		inputSearchTimer = null;
		templateJSON = {};
		defaultLocale = "en";
		theme_json = "";
		renderTemplateCreator();
		
		// Reset state variables on cleanup
		return () => {
            templateCreator = {};
        };
	},[]);

	const saveTemplateQuestions = () => {
		let question_json = JSON.parse(templateCreator.text);
		let currentDefaultLocale = templateCreator.JSON?.locale;
		let questionsData = surveyQuestionModifier(question_json)
		let canSave = canSaveBuilderQuestions(question_json)
		props.dispatchCanSave(canSave)
		if(currentDefaultLocale)
			{
				// setDefaultLocale(currentDefaultLocale);
				defaultLocale = currentDefaultLocale;			
				SurveyCore.surveyLocalization.defaultLocale = currentDefaultLocale;
			}
			else
			{
				SurveyCore.surveyLocalization.defaultLocale = defaultLocale;
			}	
		// if (canSaveQuestion) {
			clearTimeout(inputSearchTimer);
			inputSearchTimer = setTimeout(() => handleSaveBuilderData(questionsData), 500);
		// }
	}

	const handleSaveBuilderData = (questionsData) => {
		const model = templateCreator.getPlugin("translation").model;
		let locales = isTranTabInitialised ? model.getSelectedLocales() : template_locales;
		let formData = new FormData();
		questionsData.locale = defaultLocale;
		formData.append("template_id", template_id);
		formData.append("template_json", JSON.stringify(questionsData));
		formData.append("template_locales", JSON.stringify(locales));
		formData.append("theme_json", JSON.stringify(templateCreator.theme));
		Axios.post(configURL.partner_save_template_question, formData).then(response => {
			if (response.data.success !== undefined && response.data.success === true) {
				// toast.success(response.data.message);
			} else {
				// toast.warn(response.data.message);
			}
		});
	}

	const saveTemplateData = (type = "save") => {
		setConfirmSave(false);
		setLoading(true);
		let formData = new FormData();
		formData.append("category_id", category_id);
		formData.append("template_id", template_id);
		formData.append("publish_type", type);
		Axios.post(configURL.partner_save_template_builder_setting, formData).then(response => {
			setLoading(false)
			if (response.data.success !== undefined && response.data.success === true) {
				props.dispatchResetTemplateData()
				navigate("/template-dashboard")
				toast.success(response.data.message);
			} else {
				toast.warn(response.data.message);
			}
		})
	}

	const handleCustomSave = async () => {
		try {
			await saveTemplateQuestions();
			await getInitialData(() => {
				setCanSaveQuestion(true);
			})
			setCanSaveQuestion(true);
			toast.success('Template data saved.');
		} catch (error) {
			toast.warn('Template data not saved.');
			console.error(error);
		}
	};
	
	const changeTemplateLocale = (lang) => {
		props.dispatchSelectedLanguage(lang.name)
		templatePreviewModel.locale = lang.id
	}

	// const previewTemplate = () => {
	// 	let locales = isTranTabInitialised ? templateCreator.translation.getSelectedLocales() : template_locales
	// 	let usedLocales = []
	// 	usedLocales = locales.filter(item => item !== "").map(el => {
	// 		return {
	// 			id: el,
	// 			name: SurveyCore.surveyLocalization.localeNames[el] || "Default"
	// 		}
	// 	})
	// 	usedLocales.push({ id: "", name: "English" })
	// 	props.dispatchLanguages(usedLocales)
	// 	let question_json = JSON.parse(templateCreator.text);
	// 	let questionsData = surveyQuestionModifier(question_json)
	// 	templatePreviewModel = new SurveyCore.Model(JSON.stringify(questionsData));
	// 	props.dispatchSelectedLanguage(null)
	// 	templatePreviewModel.render("templatePreviewElement");
	// }

	const handleMapQuestion = async (mappings = {}) => {
		try {
			setLoading(true);
			let mappedJson = mapParentChild(JSON.parse(templateCreator.text), mappings)
			templateCreator.text = JSON.stringify(mappedJson)
			handleSaveBuilderData(mappedJson)
			let formData = new FormData()
			formData.append('template_id', template_id)
			formData.append('mappings_data', JSON.stringify(mappings))
			const saveMapping = await Axios.post(configURL.saveQuestionMapData, formData)
			if (saveMapping.data.success) {
				toast.success(saveMapping.data.message || "Mappings Saved.")
			} else {
				toast.warn(saveMapping.data.message || "Something is not right here..")
			}
			setLoading(false)
		} catch (error) {
			setLoading(false);
			toast.warn("Something is not right here..")
			console.error(error);
		}
	}

	useEffect(() => {
		templateJSON = templateCreator?.text
    },[templateCreator?.text])

		return (
			<React.Fragment>
				<EziAlert show={confirmSave}
					alerttext="Are you sure you want to save ?"
					confirmtext="Save Only"
					confirm={() => saveTemplateData("save")}
					onConfirmTwo={() => saveTemplateData("save_publish")}
					showBtnTwo={true}
					onhide={() => setConfirmSave(false)}
					confirmTwotext="Save & Publish"
					showClose
				/>
				<section className="Page-TemplateCreator">
					<div className="survey-creator-header">
						<div className="survey-creator-header-left">
							<label className="survey-theme-name">{props.categoryName || ""}</label>
							<label className="survey-template-name">{props.templateName || ""}</label>
						</div>
						<div className="survey-creator-header-right">
							<button type="button" className="template_back" onClick={() => {
								props.dispatchResetTemplateData()
								navigate("/template-dashboard")
							}}> Back to Dashboard </button>
							<label className={`survey-status-name ${props.templateStatus}`}>{(props.templateStatus) ? props.templateStatus : "New"}  {(props.publishStatus === true) ? " & Publish" : " & Unpublished"}</label>
						</div>
					</div>
					{showThemeModal &&<ThemeMappingModal id={template_id} questionType="template" onHide={() => setShowThemeModal(false)} show={showThemeModal} />}
					{showQuestionMap && <QuestionMapping
						onHide={() => setShowQuestionMap(false)}
						mapQuestions={handleMapQuestion}
						isShown={showQuestionMap}
					/>}
					{/* <TemplateBuilderTab
						changeLocale={changeTemplateLocale}
						saveTemplate={() => setConfirmSave(true)}
						preview={previewTemplate}
					/> */}
					{templateCreator?.text && <TemplateBuilderTab changeLocale={changeTemplateLocale}
					saveTemplate={() => setConfirmSave(true)} saveTemplateQuestions={saveTemplateQuestions}  creator={templateCreator} handleCustomSave={handleCustomSave}/>}
				</section>
				{loading && <EziLoader />}
			</React.Fragment>
		);
}

const mapStateToProps = (state) => {
	return {
		templateName: state.template.templateName,
		categoryName: state.template.categoryName,
		templateStatus: state.template.templateStatus,
		publishStatus: state.template.publishStatus
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		// dispatchShowThemeMap: () => dispatch(AppActions.viewThemeMap({ view: true })),
		dispatchInitial: (data) => dispatch(AppActions.setTemplateInitials(data)),
		dispatchResetTemplateData: () => dispatch(AppActions.resetTemplateState()),
		dispatchCanSave: (flag) => dispatch(AppActions.launchableTemplate({ canSave: flag })),
		dispatchLanguages: (locales) => dispatch(AppActions.setTemplateLocales({ locales })),
		dispatchSelectedLanguage: (language) => dispatch(AppActions.setTemplateLocale({ language })),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilder);