import * as Survey from "survey-core";

var widget = {
	name: "personalinfo",
	title: "Personal Info",
	iconName: "icon-personal-info",
	widgetIsLoaded: function () {
		return true;
	},
	isFit: function (question) {
		return question.getType() === "personalinfo";
	},
	htmlTemplate: "<div class='personal-info'><input class ='sv_q_text_root' /></div>",

	activatedByChanged: function (activatedBy) {
		Survey.JsonObject.metaData.addClass("personalinfo", [], null, "text");
	},
	afterRender: function (question, el) {
		var text = el.getElementsByTagName("input")[0];
		text.type = question.inputType;
		text.placeholder = question.placeHolder || "";
		if (question.inputType === 'tel' || question.inputType === 'number') {
			text.pattern = "[0-9]*";
		}

		text.onchange = function () {
			question.value = text.value;
		}
		const onValueChangedCallback = function () {
			text.value = question.value ? question.value : "";
		}
		const onReadOnlyChangedCallback = function () {
			if (question.isReadOnly) {
				text.setAttribute('disabled', 'disabled');
			} else {
				text.removeAttribute("disabled");
			}
		};
		question.readOnlyChangedCallback = onReadOnlyChangedCallback;
		question.valueChangedCallback = onValueChangedCallback;
		onValueChangedCallback();
		onReadOnlyChangedCallback();
	},
	willUnmount: function (question, el) {
		question.readOnlyChangedCallback = null;
		question.valueChangedCallback = null;
	}
};
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;
