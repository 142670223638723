import * as Survey from "survey-core";
import './Widget.scss';
import $ from "jquery";
import { uniqueGenerator } from "utility/helper";

var widget = {
    name: "nps_rating",
    title: "NPS",
    iconName: "icon-nps",
    widgetIsLoaded: function () {
        return true;
    },
    isFit: function (question) {
        return question.getType() === 'nps_rating';
    },
    activatedByChanged: function () {
        Survey.JsonObject.metaData.addClass("nps_rating", [], null, "radiogroup");
    },
    isDefaultRender: false,
    htmlTemplate: `
        <div class='nps-widget-wrapper rating-guage'>
            <fieldset class="rating-circle">
                <div class="desk-hide sel-wrap"><span id="selected-rating" class="selected-rating red"></span></div>
                <div class='nps-widget-inner rating-scale'> 
                    <span class='nps-label-left'>Will not at all recommend</span>
                    <div class='nps-radio-wrapper rating-tab red'>
                        <input class='nps-radio' type='radio' name='npsradio' value='0' />
                        <span class='nps-number' value='0'></span>
                        <span class="nps-number-val" value="0">0</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab red'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='1' /> 
                        <span class='nps-number' value='1'></span> 
                        <span class="nps-number-val" value="1">1</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab red'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='2' />
                        <span class='nps-number' value='2'></span>
                        <span class="nps-number-val" value="2">2</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab red'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='3' /> 
                        <span class='nps-number' value='3'></span>
                        <span class="nps-number-val" value="3">3</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab red'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='4' /> 
                        <span class='nps-number' value='4'></span>
                        <span class="nps-number-val" value="4">4</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab red'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='5' />
                        <span class='nps-number' value='5'></span>
                        <span class="nps-number-val" value="5">5</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab red'>
                        <input class='nps-radio' type='radio' name='npsradio' value='6' />
                        <span class='nps-number' value='6'></span>
                        <span class="nps-number-val" value="6">6</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab yellow'>
                        <input class='nps-radio' type='radio' name='npsradio' value='7' />
                        <span class='nps-number' value='7'></span>
                        <span class="nps-number-val" value="7">7</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab yellow'>
                        <input class='nps-radio' type='radio' name='npsradio' value='8' />
                        <span class='nps-number' value='8'></span>
                        <span class="nps-number-val" value="8">8</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab green'>
                        <input class='nps-radio' type='radio' name='npsradio' value='9' />
                        <span class='nps-number' value='9'></span>
                        <span class="nps-number-val" value="9">9</span>
                    </div>
                    <div class='nps-radio-wrapper rating-tab green'>
                        <input class='nps-radio' type='radio' name='npsradio' value='10' />
                        <span class='nps-number' value='10'></span>
                        <span class="nps-number-val" value="10">10</span>
                    </div>
                    <div class="nps-radio-wrapper rating-tab transparent"></div>
                    <span class='nps-label-right'>Will definitely recommend</span>
                </div>
            </fieldset>
        </div>
    `,
    afterRender: function (question, el) {
        var elements = el.getElementsByTagName("input");
        var text = el.getElementsByTagName("fieldset")[0];
        text.inputType = question.inputType;
        var matches = text.querySelectorAll("input.nps-radio");
        var elName = "npsradio_" + uniqueGenerator();
        for (let index = 0; index < matches.length; index++) {
            matches[index].name = elName
        }
        if (question.value) {
            $('input[name="' + elName + '"][value="' + question.value + '"]').prop('checked', true);
        }
        text.onchange = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onValueChangedCallback = function () {
            let selIndex = -1;
             $("#selected-rating").removeClass('red');
                   $("#selected-rating").removeClass('yellow');
                    $("#selected-rating").removeClass('green');
            for (var i = 0, l = elements.length; i < l; i++) {
                 $(elements[i]).parent().removeClass('active');
                 
                if (elements[i].checked) {
                    question.value = elements[i].value;
                    selIndex = i;
                     $(elements[i]).parent().addClass('active');
                    if(selIndex >= 0 && selIndex <= 6)
                    {
                        $("#selected-rating").addClass('red');
                    }
                    else if(selIndex >= 7 && selIndex <= 8)
                    {
                        $("#selected-rating").addClass('yellow');
                    }
                     else if(selIndex >= 9 && selIndex <= 10)
                    {
                        $("#selected-rating").addClass('green');
                    }
                }
            }
            //  for (var j = 0;  j <= selIndex; j++) {
            //     $(elements[j]).parent().addClass('active');
            //  }
            
            // $("#selected-rating").html(question.value);
        }
        var onReadOnlyChangedCallback = function () {
            if (question.isReadOnly) {
                text.setAttribute('disabled', 'disabled');
            } else {
                text.removeAttribute("disabled");
            }
        };
        question.readOnlyChangedCallback = onReadOnlyChangedCallback;
        question.valueChangedCallback = onValueChangedCallback;
        onValueChangedCallback();
        onReadOnlyChangedCallback();

    },
    willUnmount: function (question, el) {
    }
}
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;