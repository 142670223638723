import * as Survey from "survey-core";
import './Widget.scss';
import $ from "jquery";
import { uniqueGenerator } from "utility/helper";

var widget = {
    name: "hiv2",
    title: "Happiness Index",
    iconName: "icon-hi",
    questionJSON: {
        type: 'hiv2',
        name: 'hiv2',
        choices: [
            { value: 5, text: "Loving it" },
            { value: 4, text: "Joyful" },
            { value: 3, text: "At Ease" },
            { value: 2, text: "Upset" },
            { value: 1, text: "Bored" }
        ]
    },
    widgetIsLoaded: function () {
        return true;
    },
    isFit: function (question) {
        return question.getType() === 'hiv2';
    },
    activatedByChanged: function () {
        Survey.JsonObject.metaData.addClass("hiv2", [], null, "radiogroup");
    },
    isDefaultRender: true,
    // htmlTemplate: "<div class='smily-wrapper'> <fieldset> <div class='smily-inner-wrapper'> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='5' /> <span class='smily-icon very-poor'></span> <div class='smily-name'>Loving it</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='4' /> <span class='smily-icon poor'></span> <div class='smily-name'>Joyful</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='3' /> <span class='smily-icon average'></span> <div class='smily-name'>At Ease</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='2' /> <span class='smily-icon good'></span> <div class='smily-name'>Upset</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='1' /> <span class='smily-icon excellent'></span> <div class='smily-name'>Bored</div> </div> </div> </div> <fieldset/> </div>",
    onLoaded(question) {
        this.setProperties(question);
    },
    onPropertyChanged(question, propertyName, newValue) {
        this.setProperties(question);
    },
    setProperties(question) {
        question.contentQuestion.choices = question.choices;
    },
    afterRender: function (question, el) {

        // text.inputType = question.inputType;
        // var matches = text.querySelectorAll("input.smily-radio");
        // var elName = "smilyradio_" + uniqueGenerator();
        // for (let index = 0; index < matches.length; index++) {
        //     matches[index].name = elName
        // }
        // if (question.value) {
        //     $('input[name="' + elName + '"][value="' + question.value + '"]').prop('checked', true);
        // }
        const renderChoices = () => {
            var outputHTML = '';
            var allRadios = question.choices;
            outputHTML += "<div class='smily-wrapper'><fieldset><div class='smily-inner-wrapper'>";
            allRadios.forEach(function (row, index, rows) {
                var spanClassName = '';
                switch (row.value) {
                    case '5':
                        spanClassName = "very-poor";
                        break;
                    case '4':
                        spanClassName = "poor";
                        break;
                    case '3':
                        spanClassName = "average";
                        break;
                    case '2':
                        spanClassName = "good";
                        break;
                    case '1':
                        spanClassName = "excellent";
                        break;
                    default:
                        spanClassName = '';
                        break;

                };
                outputHTML += "<div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value=" + row.value + " /> <span class='smily-icon " + spanClassName + "'></span> <div class='smily-name'>" + row.text + "</div> </div> </div>";
            });


            outputHTML += "<fieldset/> </div>";

            el.innerHTML = outputHTML;
        }
        question.registerFunctionOnPropertyValueChanged(
            'visibleChoices',
            () => renderChoices()
        );
        renderChoices();
        var elements = el.getElementsByTagName("input");
        var text = el.getElementsByTagName("fieldset")[0];
        text.inputType = question.inputType;
        var matches = text.querySelectorAll("input.smily-radio");
        var elName = "smilyradio_" + uniqueGenerator();
        for (let index = 0; index < matches.length; index++) {
            matches[index].name = elName
        }
        if (question.value) {
            $('input[name="' + elName + '"][value="' + question.value + '"]').prop('checked', true);
        }

        text.onchange = function () {

            for (var i = 0, l = elements.length; i < l; i++) {
                $('.smily-wrapper input[name="' + elName + '"][value="' + elements[i].value + '"]').removeClass("checked");
                $('.smily-wrapper input[name="' + elName + '"][value="' + elements[i].value + '"]').removeClass("unchecked");
                if (elements[i].checked) {
                    question.value = elements[i].value;
                    $('input[name="' + elName + '"][value="' + elements[i].value + '"]').addClass('checked');
                }
                else {
                    $('input[name="' + elName + '"][value="' + elements[i].value + '"]').addClass('unchecked');
                }
            }
        }
        var onValueChangedCallback = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onReadOnlyChangedCallback = function () {
            // if (question.isReadOnly) {
            //     text.setAttribute('disabled', 'disabled');
            // } else {
            //     text.removeAttribute("disabled");
            // }
        };
        question.readOnlyChangedCallback = onReadOnlyChangedCallback;
        question.valueChangedCallback = onValueChangedCallback;
        onValueChangedCallback();
        onReadOnlyChangedCallback();

    },
    willUnmount: function (question, el) {
    }
}
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;
