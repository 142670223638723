import { Serializer, ComponentCollection } from "survey-core";

ComponentCollection.Instance.add({
  name: "rating_csat",
  title: "CSAT",
  iconName: "",
  questionJSON: {
    type: "rating",
    name: "rating_csat",
    displayMode: "buttons",
    csatScores: [],
  },
  onInit() {
    Serializer.addProperty("rating_csat", {
      name: "csatScores:itemvalues",
      category: "general",
    });
  },
  onLoaded(question) {
    this.setProperties(question);
  },
  onPropertyChanged(question, propertyName, newValue) {
    this.setProperties(question);
  },
  onItemValuePropertyChanged(question, options) {
    if (options.propertyName === "csatScores") {
      this.setProperties(question);
    }
  },
  setProperties(question) {
    question.contentQuestion.rateValues = question.csatScores;
  },
});